* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, sans-serif
}

:root {
  --champagne-pink: #edd3c4ff;
  --thistle: #c8adc0ff;
  --medium-slate-blue: #7765e3ff;
  --savoy-blue: #3b60e4ff;
  --black: #080708ff;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  background-color: var(--champagne-pink);
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
  justify-content: flex-start;
}

h1 {
  text-align: center;
}

.DoneToDoList,
.OpenToDoList {
  list-style: none;
  padding: 0px;
  text-align: center;
}

li {
  color: var(--black);
}

li.done>.toDoText {
  color: var(--medium-slate-blue);
  text-decoration: line-through;
}

.removeToDo {
  cursor: pointer;
}

/* CSS */
input[type=submit],
input[type=text] {
  background-color: #c8adc0ff;
  border: 2px solid #7765e3ff;
  border-radius: 30px;
  box-shadow: #7765e3ff 4px 4px 0 0;
  color: #7765e3ff;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  padding: 0 18px;
  max-width: 150px;
  margin: 10px;
}

input[type=submit]:hover,
input[type=text]:hover {
  background-color: #fff;
}

input[type=submit]:active {
  box-shadow: #7765e3ff 2px 2px 0 0;
  border: 2px solid #7765e3ff;
  transform: translate(2px, 2px);
}

input[type=text]:focus,
input[type=text]:focus-visible {
  outline: none;
}

hr {
  width: 80%;
}

form {
  display: flex;
  justify-content: center;
}

.gif {
  width: 80%;
}